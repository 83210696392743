@forward 'scss';

html,
body,
pre {
  padding: 0;
  margin: 0;
  font-family: "Roboto Flex", Arial, Helvetica, sans-serif;
  font-size: 18px;
  background-color: var(--bg);
  line-height: 1.5;

  >footer {
    position: sticky;
    top: 100vh;
  }
}

*::selection {
  background: var(--primary);
  background-color: var(--primary);
  color: var(--bg);
}

pre {
  white-space: pre-wrap;
  background-color: inherit;
  color: inherit;
  padding-bottom: 1rem;

  &::first-letter {
    font-size: 125%;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a,
a:visited,
a:focus {
  color: var(--tertiary);
  text-decoration: none;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: currentColor;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 250ms ease-in;
  }

  &:hover {
    color: var(--primary);

    &:after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  &.primary {
    color: var(--primary);
  }
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--primary);
  font-weight: 300;
  text-transform: uppercase;
  display: inline-block;
  width: fit-content;
  position: relative;

  &:before,
  &:after {
    content: '';
    background: linear-gradient(90deg, currentColor 50%, transparent 100%);
    height: 2px;
    width: 100%;
    position: absolute;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 250ms ease-in;

  }

  &:before {
    top: .25rem;
  }

  &:after {
    bottom: .25rem;
  }
}

h1::first-letter,
h2::first-letter,
h3::first-letter,
h4::first-letter,
h5::first-letter {
  font-size: 125%
}

h1 {
  font-weight: 400;
  font-size: 2rem;
}

p::first-letter {
  font-size: 125%;
}

section {
  padding: 2rem 1em;
  max-width: 100rem;

  &:hover {

    >h1,
    >h2,
    >h3,
    >h4,
    >h5 {

      &:before,
      &:after {
        transform-origin: left;
        transform: scaleX(1);
      }
    }
  }
}

strong {
  font-weight: 500;
}

hr {
  height: 2px;
  border: none;
  background: linear-gradient(90deg, transparent 1%, var(--primary) 50%, transparent 100%);
  margin: 0;
}

// Logos
.ds-logo {
  height: 2.5rem;
  padding-right: 0.25rem;
  position: relative;
  top: 0.65rem;
}

// General content
.content {
  margin: 2.5rem auto 0 auto;
  padding: 0;
  min-height: calc(100vh - 4.5rem);

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    padding: 0;
  }

  .content-wrapper {
    margin-top: 3.25rem;
  }
}

img {
  user-select: none;
}

.responsive-img {
  max-width: 100%;
}

// Button
a.btn {
  display: inline-block;
}

.btn,
a.btn {
  text-transform: uppercase;
  padding: .25rem 1rem;
  background-color: transparent;
  border: 1px solid currentColor;
  color: var(--tertiary);
  font-size: 1.15rem;
  cursor: pointer;
  margin-bottom: 1rem;
  position: relative;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: var(--tertiary);
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 250ms ease-in;
  }

  &:hover,
  &.visited:hover {
    border-color: var(--light);
    color: var(--primary);

    &:after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  &:disabled {
    cursor: initial;
    opacity: 60%;

    &:after {
      background: transparent;
    }

    &:hover,
    &.visited:hover {
      border-color: currentColor;
      color: var(--secondary);
      box-shadow: none;

      &:after {
        transform: scaleX(0);
      }
    }
  }

  &__warn {
    color: var(--warn);
  }

}

.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  inset: 0;
  background-color: rgba(var(--bg), .8);
  z-index: 1000;
}

// Sections containing images

.img-right {
  display: grid;
  grid-template-rows: 1fr auto;

  img {
    margin: 1rem 0 0 1rem;
    float: right;
    max-width: 30%;
    max-height: 25rem;
  }
}

.img-left {
  display: grid;
  position: relative;
  grid-template-rows: 1fr auto;
  background-color: var(--primary);
  background-size: 400%;
  color: black;
  box-shadow: 0 0 0 100vmax var(--primary);
  clip-path: inset(0 -100vmax);

  h2 {
    color: var(--bg);
  }

  a,
  a:visited,
  a:focus {
    color: var(--tertiary);

    &:hover {
      color: var(--light);
    }
  }

  .btn, a.btn {
    background-color: var(--primary);

    &:hover {
      color: var(--primary);
    }
  }

  img {
    float: left;
    margin: 1rem 1rem 0 0;
    max-width: 30%;
    max-height: 20rem;
  }

  &:after {
    content: "";
    background-color: var(--bg);
    height: 100%;
    width: 200vw;
    z-index: 2;
    position: absolute;
    inset: 0;
    left: -50vw;
    animation: img-left-animation 2s ease forwards;
  }
}

// images

.flip {
  animation: flip 1.5s;
}

// Layout

.horizontal-spacer {
  height: var(--margin);
  content: '';
}

// Simple colors
.pink {
  color: var(--tertiary);
}

.light {
  color: var(--light);
}

@media screen and (max-width: 800px) {

  .img-right img,
  .img-left img {
    float: none;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
  }

  .no-small {
    display: none;
  }
}

@keyframes img-left-animation {
  100% {
    width: 0;
  }
}

@keyframes flip {
  from {
    transform: rotateY(90deg)
  }

  to {
    transform: rotateY(0deg)
  }
}