:root {
  --primary: #73c9b9;
  --secondary: hsl(177, 87%, 42%);
  --tertiary: hsl(171, 45%, 94%);
  --accent: hsla(217, 87%, 42%, 0.99);
  --bg: hsl(0, 0%, 21%);
  --warn: rgba(230, 86, 86, 0.99);
  --warnAccent: rgba(212, 84, 84, 0.712);
  --light: hsla(0, 0%, 94%, 1);

  --primary50: hsla(177, 87%, 42%, 0.5);
  --secondary50: hsla(169, 44%, 62%, 0.5);
  --tertiary50: hsla(347, 63%, 62%, 0.5);
  --accent50: hsla(217, 87%, 42%, 0.5);
  --bg50: hsla(0, 0%, 21%, 0.5);
  --warn50: hsla(0, 74%, 62%, 0.5);
  --warnAccent50: hsla(0, 60%, 58%, 0.5);
  --light50: hsla(0, 0%, 94%, 5);

  --gradient: linear-gradient(90deg, rgba(54, 54, 54, 1) 1%, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 1) 70%, rgba(54, 54, 54, 1) 100%);

  --modalHeight: 70vh; 
  --modalWidth: 70vw;
  --modalHeaderHeight: 3rem;
  --modalFooterHeight: 2rem;

  --shadow: hsla(0, 0%, 0%, 0.4);
  --shadowLight: hsla(0, 0%, 0%, 0.2);
  --shadowGradient: linear-gradient(to right, $shadow, rgba(0, 0, 0, 0));
  --primaryGradient: linear-gradient(to right, $primary, rgba(0, 0, 0, 0));
  --accentGradient: linear-gradient(to right, $accent, $primary);
}

.warning {
  color: var(--warnAccent);
}

.tertiary {
  color: var(--bg);
  background-color: var(--primary);
  padding: 0 .5rem;
}