.slider-container {
  max-width: 99dvw;
  margin: 0 auto;
  overflow: hidden;
  display: block;
  .slider {
      width: 100%;
      height: var(--height);
      max-width: 100dvw;
      overflow: hidden;
      mask-image: linear-gradient(to right, transparent, black 10% 90%, transparent);
  
      .list {
        display: flex;
        width: 100%;
        width: calc(var(--width) * var(--quantity));
        position: relative;
  
        .item {
          width: var(--width);
          height: var(--height);
          position: absolute;
          left: 100%;
          animation: autoRun 30s linear infinite;
          animation-delay: calc((30s / var(--quantity)) * (var(--position) - 1) * -1);
          transition: filter 1s ease-in-out;
  
          img {
            width: 100%;
          }
        }
      }

      &:hover {
        .item {
          animation-play-state: paused!important;
          filter: grayscale(1);

          &:hover {
            filter: grayscale(0);
          }
        }
      }
    }
}

@media (prefers-reduced-motion) {
  .slider-container {
    display: none;
  }
}

@keyframes autoRun {
  from {
    left: 120%;
  }
  to {
    left: calc(var(--width) * -1);
  }
}

