.modal-background {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, .6);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    position: relative;
    background-color: var(--bg);
    color: var(--light);
    padding: calc(var(--margin) * 1.5);
    line-height: 1.3em;
    border: 2px solid var(--primary);
    border-radius: .25rem;
    max-width: 35rem;

    p {
      line-height: 1.5rem;
    }

    button {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    a.btn {
      text-align: center;
    }
  }
}